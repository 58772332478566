import type { NextPage } from 'next';
import Grid from '@mui/material/Grid';
import Image from 'next/image';
import useUser from '../auth/useUser';
import { Policy } from 'ui';
import CompanyButton from '../components/CompanyButton';
import logoEfigas from '../public/images/Logo_efigas_full.svg';
import logoGascaribe from '../public/images/Logo_Gases_Two_lines.svg';
import logoGuajira from '../public/images/Logo_gases_guajira_full.svg';
import { Typography } from '@mui/material';

const Home: NextPage = () => {
  const currentUser = useUser();

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      marginTop={5}
      spacing={3}
    >
      <Grid item xs={12}>
        <Typography fontSize={30} align="center" fontWeight="medium">
          Seleccione su gasera:
        </Typography>
      </Grid>
      <Policy user={currentUser} roles={['access_gdc']}>
        <Grid item>
          <CompanyButton link="/gdc/projects">
            <Image src={logoGascaribe} alt="Gascaribe" height={105} />
          </CompanyButton>
        </Grid>
      </Policy>
      <Policy user={currentUser} roles={['access_efg']}>
        <Grid item>
          <CompanyButton link="/efg/projects">
            <Image src={logoEfigas} alt="Efigas" height={100} />
          </CompanyButton>
        </Grid>
      </Policy>
      <Policy user={currentUser} roles={['access_gdg']}>
        <Grid item>
          <CompanyButton link="/gdg/projects">
            <Image src={logoGuajira} alt="Gasguajira" height={120} />
          </CompanyButton>
        </Grid>
      </Policy>
    </Grid>
  );
};

export default Home;
